import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Link, useLocation, useHistory} from 'react-router-dom'
import qs from 'qs'

import {getSearchClient, indexName} from '../helpers/algoliasearch'
import {
	InstantSearch,
	connectSearchBox,
	connectHits,
	connectRefinementList,
	connectStateResults,
	connectPagination,
	connectStats,
	PoweredBy,
	Configure,
} from 'react-instantsearch-dom'

import ProjectSummaryCard from './ProjectSummaryCard'
import SearchBox from './Search/SearchBox'
import Header from './Header'
import {DomainList} from './TextComponents'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import {Close} from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterIcon from '@material-ui/icons/FilterList'
import MuiPagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'

const searchClient = getSearchClient()

const PageContainer = styled.main`
	margin: 2vh 2vw;
	@media (max-width: 880px) {
		margin: 2vh 0;
	}
	.pagination {
		display: flex;
		justify-content: center;
		margin: 12px;
	}
	.resultCount {
		display: flex;
		justify-content: flex-end;
		margin: 12px;
	}
`

const RefinementDomainList = styled(DomainList)`
	margin: 12px 0;
	@media (max-width: 880px) {
		display: none;
	}
`

const ConnectedSearchBox = connectSearchBox(SearchBox)

const ResultsContainer = styled.div`
	display: flex;
	position: relative;
	align-items: flex-start;
	.filterMenu {
		position: sticky;
		top: 80px;
		flex: 240px 0 0;
		margin-right: 24px;
		max-height: calc(100vh - 80px);
		overflow-y: auto;
		overflow-x: hidden;

		& .MuiListItemIcon-root {
			min-width: 32px;
		}

		.closeButton {
			display: none;
		}

		@media (max-width: 880px) {
			display: ${({showFilters}) => (showFilters ? 'block' : 'none')};
			position: fixed;
			top: 56px;
			width: 100vw;
			height: calc(100vh - 56px);
			background-color: rgba(139, 87, 42, 0.3);

			.closeButton {
				display: initial;
				position: fixed;
				bottom: 0;
				width: 100%;
			}

			.filterHeading {
				padding: 1rem;
				background-color: white;
			}
		}
	}
`

const FilterButton = styled.div`
	display: flex;
	justify-content: center;
	@media (min-width: 880px) {
		display: none;
	}
	padding: 8px 0;
`

const DEBOUNCE_TIME = 400
const createURL = state => `?${qs.stringify(state)}`

const searchStateToUrl = searchState =>
	searchState ? `${createURL(searchState)}` : ''

const urlToSearchState = ({search}) => qs.parse(search.slice(1))

const Search = () => {
	let location = useLocation()
	let history = useHistory()

	const [searchState, setSearchState] = useState(urlToSearchState(location))
	const [debouncedSetState, setDebouncedSetState] = useState(null)
	const [showFilters, setShowFilters] = useState(false)

	useEffect(() => {
		setSearchState(urlToSearchState(location))
	}, [location])

	const onSearchStateChange = updatedSearchState => {
		clearTimeout(debouncedSetState)

		if ('refinementList' in updatedSearchState) {
			Object.keys(updatedSearchState.refinementList).forEach(key => {
				if (updatedSearchState.refinementList[key] === '')
					delete updatedSearchState.refinementList[key]
			})
		}

		setDebouncedSetState(
			setTimeout(() => {
				history.push(
					searchStateToUrl(updatedSearchState),
					updatedSearchState
				)
			}, DEBOUNCE_TIME)
		)

		setSearchState(updatedSearchState)
	}

	const secondaryFacetAttributes = [
		{algoliaAttribute: 'citiesOfOperation', uiLabel: 'City'},
		{algoliaAttribute: 'projectStatus', uiLabel: 'Project Status'},
		{algoliaAttribute: 'budget.sources', uiLabel: 'Source of Funding'},
		{algoliaAttribute: 'pathways', uiLabel: 'Pathway'},
	]
	return (
		<div>
			<Header />
			<PageContainer>
				<InstantSearch
					searchClient={searchClient}
					indexName={indexName}
					searchState={searchState}
					onSearchStateChange={onSearchStateChange}
					createURL={createURL}
				>
					<Configure
						optionalFilters="recordStatus:Published"
						hitsPerPage={12}
						distinct={1}
					/>
					<ConnectedSearchBox />
					<FilterButton>
						<Button
							startIcon={<FilterIcon />}
							variant="outlined"
							onClick={() => setShowFilters(true)}
						>
							Filters
						</Button>
					</FilterButton>
					<LoadingIndicator />
					<RefinementList attribute="pathways" />
					<div className="resultCount">
						<Stats />
					</div>
					<ResultsContainer showFilters={showFilters}>
						<div className="filterMenu">
							<Typography className="filterHeading" variant="h6">
								Filters
							</Typography>
							{secondaryFacetAttributes.map(
								({algoliaAttribute, uiLabel}) => (
									<SecondaryRefinementList
										attribute={algoliaAttribute}
										key={algoliaAttribute}
										label={uiLabel}
									/>
								)
							)}
							<Button
								className="closeButton"
								variant="contained"
								size="large"
								color="primary"
								onClick={() => setShowFilters(false)}
							>
								Close
							</Button>
						</div>
						<Hits />
					</ResultsContainer>
					<div className="pagination">
						<Pagination />
					</div>
					<PoweredBy />
				</InstantSearch>
			</PageContainer>
		</div>
	)
}

export default Search

const HitsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
	grid-gap: 24px;
	margin: 12px 0 24px;
`

const Hits = connectHits(({hits}) => (
	<HitsContainer>
		{hits.map(hit => (
			<Link to={`/project/${hit.projectId}`} key={hit.objectID}>
				<ProjectSummaryCard projectData={hit} />
			</Link>
		))}
	</HitsContainer>
))

const RefinementList = connectRefinementList(({items, refine}) => (
	<RefinementDomainList fontSize="14px">
		{items.map(item => (
			<Button
				variant={item.isRefined ? 'outlined' : 'text'}
				color={item.isRefined ? 'primary' : 'default'}
				onClick={event => {
					event.preventDefault()
					refine(item.value)
				}}
				key={item.label}
				endIcon={item.isRefined ? <Close /> : null}
				className="domain"
			>
				{item.label} ({item.count})
			</Button>
		))}
	</RefinementDomainList>
))

const SecondaryRefinementList = connectRefinementList(
	({items, refine, label}) => (
		<div>
			<Accordion elevation={0}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{label}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List dense disablePadding>
						{items.map(item => (
							<ListItem
								button
								key={item.label}
								onClick={() => refine(item.value)}
							>
								<ListItemIcon>
									{item.isRefined ? (
										<CheckBoxIcon />
									) : (
										<CheckBoxOutlineBlankIcon />
									)}
								</ListItemIcon>
								<ListItemText primary={item.label} />
							</ListItem>
						))}
					</List>
				</AccordionDetails>
			</Accordion>
		</div>
	)
)

const LoadingIndicator = connectStateResults(({isSearchStalled}) =>
	isSearchStalled ? 'Loading...' : null
)

const Pagination = connectPagination(
	({currentRefinement, nbPages, createURL}) => (
		<MuiPagination
			page={currentRefinement}
			count={nbPages}
			color="secondary"
			renderItem={item => (
				<PaginationItem
					component={Link}
					to={createURL(item.page)}
					{...item}
				/>
			)}
		/>
	)
)

const Stats = connectStats(({nbHits}) => (
	<Typography variant="subtitle1">{nbHits} projects</Typography>
))
