import {useQuery} from 'react-query'
import {useAuth} from '../authContext'

function useFetchPublicDetails() {
	const {crossroads} = useAuth()
	const publicDetailsQuery = useQuery(
		'publicDetails',
		() => crossroads.get('/me?publicDetails=y'),
		{
			staleTime: Infinity,
		}
	)
	return publicDetailsQuery
}
export default useFetchPublicDetails
