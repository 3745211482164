import React from 'react'
import styled from 'styled-components'

import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const ResourceCardContent = styled(CardContent)`
	display: flex;
	align-items: center;
	.header-icon {
		max-height: 48px;
		max-width: 48px;
		margin: 12px;
	}
`
const StyledCard = styled(Card)`
	display: flex;
`

function ResourceCard({link, label, subtitle, type}) {
	return (
		<StyledCard
			elevation={2}
			component="a"
			href={link}
			target="_blank"
			rel="noopener noreferrer"
		>
			<CardActionArea>
				<ResourceCardContent>
					{type && (
						<img
							src={`${process.env.PUBLIC_URL}/resource-icons/${type}.png`}
							alt={`${type} icon`}
							className="header-icon"
						/>
					)}
					<div className="content">
						<Typography variant="h5">{label}</Typography>
						<Typography variant="subtitle2">{subtitle}</Typography>
					</div>
				</ResourceCardContent>
			</CardActionArea>
		</StyledCard>
	)
}

export default ResourceCard
