import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react'

Sentry.init({
	dsn:
		'https://600695d7d895fb39376a71f33b2ee2b0@o971414.ingest.sentry.io/4506670914863104',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [
				'localhost',
				/^https:\/\/dev\.bethechange\.teachforindia\.org\/projects/,
			],
		}),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorkerRegistration.register({
	onUpdate: registration => {
		registration?.waiting.postMessage({type: 'SKIP_WAITING'})
		window.location.reload()
	},
})
