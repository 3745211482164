import {initSearchClient} from '../algoliasearch'
import {useQuery} from 'react-query'

function fetchMyProject(queryName, email) {
	const algoliaIndex = initSearchClient()
	return algoliaIndex.search({
		filters: `projectLeaders.email:${email}`,
		typoTolerance: false,
		responseFields: ['hits'],
		attributesToRetrieve: [
			'projectName',
			'projectId',
			'citiesOfOperation',
			'primaryPathway',
			'pathways',
			'yearInitiated',
			'projectSummary',
			'projectStatus',
			'legacy',
			'scopeOfImpact',
		],
		attributesToHighlight: ['projectId'],
		distinct: 1,
	})
}

/**
 * Fetches list of projects for a user
 * @param {string} email The email for which to get projects
 */
const useFetchMyProject = email => {
	const myProjectQuery = useQuery(['myProject', email], fetchMyProject, {
		staleTime: 10 * 60 * 1000,
		enabled: email,
	})
	return myProjectQuery
}
export default useFetchMyProject
