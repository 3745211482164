import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link, useRouteMatch} from 'react-router-dom'

import SearchIcon from '@material-ui/icons/Search'
import MyProjectIcon from '@material-ui/icons/ArtTrack'
import LogoutIcon from '@material-ui/icons/ExitToApp'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import MuiAppBar from '@material-ui/core/AppBar'
import MuiToolbar from '@material-ui/core/Toolbar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CreateProjectIcon from '@material-ui/icons/PostAdd'
import Tooltip from '@material-ui/core/Tooltip'
import {Avatar, ListItemIcon, ListItemText} from '@material-ui/core'

import logo from '../logo.svg'

import {useAuth} from '../helpers/authContext'
import useFetchMyProject from '../helpers/hooks/useFetchMyProject'
import useFetchPublicDetails from '../helpers/hooks/useFetchPublicDetails'

const AppBar = styled(MuiAppBar)`
	&.MuiAppBar-colorPrimary {
		background-color: white;
		color: black;
	}
`
const Toolbar = styled(MuiToolbar)`
	display: flex;
	justify-content: space-between;

	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: initial;
	}
`
const ContainerStart = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
`
const ContainerEnd = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-direction: row-reverse;
`

const SearchButton = styled(Button).attrs(() => ({
	variant: 'outlined',
	fullWidth: true,
}))`
	&.MuiButton-root {
		margin: 0 16px;
		border-radius: 12px;

		@media (max-width: 880px) {
			margin: 0;
		}
	}
`
const HomeLink = styled(Link)`
	.app-title {
		@media (max-width: 880px) {
			display: none;
		}
	}
	display: flex;
	align-items: center;
	img.logo {
		height: 32px;
		padding: 8px;
	}
`

const UserButtonIcon = styled(Avatar)`
	&.MuiAvatar-root {
		height: 24px;
		width: 24px;
	}
`

const Header = ({RightItems}) => {
	const {logout} = useAuth()
	const [menuAnchor, setMenuAnchor] = useState(null)
	const isSearchPage = useRouteMatch('/search')
	const isCreatePage = useRouteMatch(['/edit', '/new', '/create-project'])
	// const isCreatePage = true

	const handleClick = event => {
		setMenuAnchor(event.currentTarget)
	}

	const handleClose = () => {
		setMenuAnchor(null)
	}

	const publicDetailsQuery = useFetchPublicDetails()

	const email = publicDetailsQuery.isSuccess
		? publicDetailsQuery.data.email
		: null

	const singleProjectQuery = useFetchMyProject(email)

	let pictureURL = null,
		displayName = null
	if (publicDetailsQuery.isSuccess) {
		pictureURL = publicDetailsQuery.data.publicDetails.pictureURL
		displayName = publicDetailsQuery.data.publicDetails.displayName
	}

	return (
		<>
			<AppBar position="sticky">
				<Toolbar>
					<ContainerStart>
						<HomeLink to="/">
							<img className="logo" src={logo} alt="" />
							<Typography variant="h6" className="app-title">
								Be The Change
							</Typography>
						</HomeLink>
					</ContainerStart>
					<ContainerEnd>
						<IconButton color="inherit" onClick={handleClick}>
							<UserButtonIcon src={pictureURL} alt={displayName}>
								{displayName?.charAt(0)}
							</UserButtonIcon>
						</IconButton>
						{!isCreatePage && (
							<Tooltip title="Create Project">
								<IconButton
									component={Link}
									to="/create-project"
								>
									<CreateProjectIcon />
								</IconButton>
							</Tooltip>
						)}
						<Menu
							id="simple-menu"
							anchorEl={menuAnchor}
							keepMounted
							open={Boolean(menuAnchor)}
							onClose={handleClose}
						>
							{singleProjectQuery.isSuccess &&
							singleProjectQuery.data.hits.length > 0
								? singleProjectQuery.data.hits.map(hit => (
										<MenuItem
											component={Link}
											to={`/project/${hit.projectId}`}
											key={hit.projectId}
										>
											<ListItemIcon>
												<MyProjectIcon />{' '}
											</ListItemIcon>
											<ListItemText
												primary="My Project"
												secondary={hit.projectName}
											/>
										</MenuItem>
								  ))
								: null}
							<MenuItem onClick={logout}>
								<ListItemIcon>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText primary="Logout" />
							</MenuItem>
						</Menu>

						{RightItems && <RightItems />}
						{!isSearchPage && (
							<SearchButton
								startIcon={<SearchIcon />}
								component={Link}
								to="/search"
							>
								Search Projects
							</SearchButton>
						)}
					</ContainerEnd>
				</Toolbar>
			</AppBar>
		</>
	)
}

Header.propTypes = {
	RightItems: PropTypes.element,
}

export default Header
