import React from 'react'
import styled from 'styled-components'

import {Link as RouterLink} from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import MuiLink from '@material-ui/core/Link'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HomeIcon from '@material-ui/icons/Home'
import ReloadIcon from '@material-ui/icons/Replay'

const ErrorMessageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 12px;
`
const ErrorCard = styled(Card)`
	.MuiInputBase-root {
		font-family: monospace;
		color: rgba(0, 0, 0, 0.7);
	}
`

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			errorName: error.name,
			errorMessage: error.message,
		}
	}

	render() {
		const {hasError, errorMessage, errorName} = this.state

		if (hasError) {
			return (
				<ErrorMessageContainer>
					<ErrorCard variant="outlined">
						<CardContent>
							<Typography variant="h4" role="alert">
								Something went wrong.
							</Typography>
							<CardActions>
								{/* TODO: attempt to add an intelligent back button here */}
								<Button
									variant="outlined"
									startIcon={<ReloadIcon />}
									color="primary"
									onClick={() =>
										this.setState({hasError: false})
									}
								>
									Reload page
								</Button>
								<Button
									variant="outlined"
									startIcon={<HomeIcon />}
									color="primary"
									component={RouterLink}
									to="/"
									onClick={() =>
										this.setState({hasError: false})
									}
								>
									Go to Homepage
								</Button>
							</CardActions>
							<Typography variant="subtitle1">
								Please reach out to{' '}
								<MuiLink
									href="mailto:helpdesk@teachforindia.org"
									color="primary"
								>
									helpdesk@teachforindia.org
								</MuiLink>{' '}
								if this issue persists
							</Typography>
							<br />
							<Accordion elevation={0}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
								>
									<Typography>Error details</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<TextField
										label={errorName}
										value={errorMessage}
										InputProps={{
											readOnly: true,
										}}
										error
										fullWidth
										multiline
									/>
								</AccordionDetails>
							</Accordion>
						</CardContent>
					</ErrorCard>
				</ErrorMessageContainer>
			)
		} else return this.props.children
	}
}

export default ErrorBoundary
