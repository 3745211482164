import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Paper from '@material-ui/core/Paper'
import {LocationOn, TrendingUp, StarOutline} from '@material-ui/icons'
import {DomainList} from './TextComponents'

const Card = styled(Paper).attrs(() => ({
	elevation: 4,
}))`
	&.MuiPaper-rounded {
		border-radius: 8px;
	}
	padding: 16px;
	overflow: hidden;

	.project-title {
		font-weight: bold;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.7);
	}

	.project-initiated {
		font-size: 12px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
	}

	.project-info {
		font-size: 14px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.7);
		margin-top: 20px;
		.MuiSvgIcon-fontSizeSmall {
			font-size: 14px;
		}
		display: grid;
		grid-template-columns: 1fr 2fr;
	}

	.project-summary {
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.7);
		margin-top: 12px;
		height: calc(5 * 20px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-line;
	}
`
const SmallDomainList = styled(DomainList).attrs(() => ({
	fontSize: '12px',
}))`
	margin: 16px 0 0 0;
`

const ProjectSummaryCard = ({projectData}) => {
	const {
		projectName,
		yearInitiated,
		pathways,
		citiesOfOperation,
		projectStatus,
		legacy,
		projectSummary,
		iLab,
	} = projectData

	return (
		<Card>
			<div className="project-title">{projectName}</div>
			<div className="project-initiated">{yearInitiated}</div>
			<SmallDomainList>
				{pathways &&
					pathways.map(domain => (
						<div className="domain" key={domain}>
							{domain}
						</div>
					))}
			</SmallDomainList>
			<div
				className="project-info"
				style={{display: 'flex', justifyContent: 'space-between'}}
			>
				<div className="project-location">
					<LocationOn fontSize="small" />{' '}
					{Array.isArray(citiesOfOperation)
						? citiesOfOperation[0]
						: citiesOfOperation}
				</div>
				<div className="project-status">
					<TrendingUp fontSize="small" />{' '}
					{legacy ? projectStatus + ' , ' + legacy : projectStatus}
				</div>
				{iLab && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<StarOutline />
						iLab Project
					</div>
				)}
			</div>
			<div className="project-summary">{projectSummary}</div>
		</Card>
	)
}

ProjectSummaryCard.propTypes = {
	/** The project data to show in the card */
	projectData: PropTypes.shape({
		projectName: PropTypes.string,
		yearInitiated: PropTypes.string,
		pathways: PropTypes.arrayOf(PropTypes.string),
		citiesOfOperation: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(PropTypes.string),
		]),
		projectSummary: PropTypes.string,
		projectStatus: PropTypes.string,
		legacy: PropTypes.string,
	}).isRequired,
}

export default ProjectSummaryCard
