import React, {useState, useEffect, useContext} from 'react'
import Crossroads from 'crossroads-js'

import getCrossroadsAccountsEndpoints from './crossroads-accounts-endpoints'
import {useHistory} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import Splash from '../components/Splash'

const AuthContext = React.createContext()

const crossroads = new Crossroads({
	apiKey: process.env.REACT_APP_CROSSROADS_API_KEY,
	host: process.env.REACT_APP_CROSSROADS_HOST,
	port: parseInt(process.env.REACT_APP_CROSSROADS_PORT),
	tokenExpiryHandler: refreshAccessToken,
})

const getAccountsObject = () => {
	const scopes = [
		'openid',
		'https://www.googleapis.com/auth/userinfo.email',
		'https://www.googleapis.com/auth/userinfo.profile',
	]
	const crossroadsAccountsObject = getCrossroadsAccountsEndpoints(
		process.env.REACT_APP_APPLICATION_HOST,
		{
			clientId: process.env.REACT_APP_CROSSROADS_CLIENT_ID,
			host: process.env.REACT_APP_CROSSROADS_HOST,
			port: parseInt(process.env.REACT_APP_CROSSROADS_PORT),
			shouldAppBeSecure: true,
		},
		scopes
		// process.env.REACT_APP_REDIRECT_URI
	)

	return crossroadsAccountsObject
}

function refreshAccessToken(originalParams) {
	const crossroadsAccountsObject = getAccountsObject()
	return fetch(crossroadsAccountsObject.accountsEndpoints.refresh, {
		credentials: 'include',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({accessToken: crossroads.accessToken}),
	})
		.then(response => response.json())
		.then(json => {
			const account = json.accounts[0]
			crossroads.updateAccessToken(account.accessToken)
			const {options} = originalParams
			options.headers.Authorization = `Basic ${new Buffer(
				process.env.REACT_APP_CROSSROADS_API_KEY +
					':' +
					crossroads.accessToken
			).toString('base64')}`
			return crossroads.call(options, originalParams.body)
		})
}

async function validateAndRefreshAccessTokenInTheBackground(
	currentAccessToken
) {
	crossroads.updateAccessToken(currentAccessToken)
	try {
		await crossroads.get('/status')
		return crossroads.accessToken
	} catch (error) {
		// TO DO handle this
		console.log(error)
	}
}

const AuthProvider = props => {
	const [accessToken, setAccessToken] = useState(null)
	const [email, setEmail] = useState(null)
	const [accounts, setAccounts] = useState(null)
	const history = useHistory()

	const crossroadsAccountsObject = getAccountsObject()
	const {accountsEndpoints} = crossroadsAccountsObject

	const logout = () => {
		fetch(accountsEndpoints.destroy, {
			credentials: 'include',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({accessToken}),
		}).then(() => {
			setAccessToken(null)
			setEmail(null)
			history.replace('/login')
		})
	}

	useEffect(() => {
		fetch(accountsEndpoints.get, {
			credentials: 'include',
		})
			.then(response => response.json())
			.then(json => {
				const {accounts} = json
				if (accounts.length)
					crossroads.updateAccessToken(accounts[0].accessToken)
				setAccounts(accounts)
			})
	}, [accountsEndpoints.get])

	useEffect(() => {
		if (accounts !== null) {
			if (accounts.length === 0) {
				history.replace('/login')
			} else {
				const currentAccessToken = accounts[0].accessToken

				async function backgroundUpdate() {
					const updatedAccessToken = await validateAndRefreshAccessTokenInTheBackground(
						currentAccessToken
					)
					if (currentAccessToken !== updatedAccessToken)
						setAccessToken(updatedAccessToken)
				}

				setAccessToken(currentAccessToken)
				setEmail(accounts[0].email)
				backgroundUpdate()
			}
		}
	}, [accounts, history])
	if (!accounts)
		return (
			<Splash>
				<CircularProgress />
			</Splash>
		)
	return (
		<AuthContext.Provider
			value={{
				accounts,
				accessToken,
				email,
				logout,
				crossroads,
				crossroadsBaseURL: crossroadsAccountsObject.baseURL,
				loginURI: crossroadsAccountsObject.loginURI,
				loginURIForced: crossroadsAccountsObject.loginURIForced,
			}}
			{...props}
		/>
	)
}
const useAuth = () => useContext(AuthContext)

export {AuthProvider, useAuth}
