import React, {lazy, Suspense} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {ReactQueryCacheProvider, QueryCache} from 'react-query'
import 'typeface-open-sans'

import {AuthProvider} from './helpers/authContext'
import Home from './components/Home'
import ErrorBoundary from './components/ErrorBoundaries/ErrorBoundary'
import Loading from './components/Loading'
import Search from './components/Search'

const Login = lazy(() => import('./components/Login'))
const ProjectDetails = lazy(() =>
	import('./components/ProjectDetails/ProjectDetails')
)
const Editor = lazy(() => import('./components/ProjectEditor/Editor'))
const CreateSwitcher = lazy(() =>
	import('./components/ProjectEditor/CreateSwitcher')
)
const Resources = lazy(() => import('./components/Resources/Resources'))

const theme = createTheme({
	palette: {
		primary: {
			main: '#8b572a',
		},
		secondary: {
			main: '#19c6a4',
		},
	},
	typography: {
		fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
	},
	props: {
		MuiTextField: {
			variant: 'outlined',
		},
		MuiSelect: {
			variant: 'outlined',
		},
		MuiInput: {
			fullWidth: true,
		},
	},
	overrides: {
		MuiMenuItem: {
			// For ListItem, change this to MuiListItem
			root: {
				'&$selected': {
					backgroundColor: '#cfcfcf',
					border: '0.8px solid grey',
				},
			},
		},
		MuiTypography: {
			h1: {
				fontSize: '1.8rem',
				color: 'rgba(0, 0, 0, 0.5)',
				fontWeight: 'bold',
				textTransform: 'uppercase',
				margin: '18px 0',
			},
			h2: {
				fontSize: '1.6rem',
				color: 'rgba(0, 0, 0, 0.5)',
				fontWeight: 'bold',
				textTransform: 'uppercase',
				margin: '12px 0',
			},
			h5: {
				fontWeight: 'bold',
				fontSize: '16px',
				color: 'rgba(0, 0, 0, 0.7)',
				textTransform: 'uppercase',
			},
		},
	},
})

const queryCache = new QueryCache()

function App() {
	return (
		<Router>
			<MuiThemeProvider theme={theme}>
				<ReactQueryCacheProvider queryCache={queryCache}>
					<AuthProvider>
						<ErrorBoundary>
							<Suspense fallback={<Loading />}>
								<Switch>
									<Route
										exact
										path={[
											'/',
											'/resources',
											'/projects',
											'/myprojects',
											'/ilabprojects',
										]}
									>
										<Home />
									</Route>
									<Route path="/login">
										<Login />
									</Route>
									<Route path="/project/:projectID">
										<ProjectDetails />
									</Route>
									<Route path="/search">
										<Search />
									</Route>
									<Route
										path={[
											'/edit/:projectID',
											'/new/:projectID',
											'/new',
										]}
									>
										<Editor />
									</Route>
									<Route path="/create-project">
										<CreateSwitcher />
									</Route>
									<Route path="/resources-old">
										<Resources />
									</Route>
								</Switch>
							</Suspense>
						</ErrorBoundary>
					</AuthProvider>
				</ReactQueryCacheProvider>
			</MuiThemeProvider>
		</Router>
	)
}

export default App
