import React, {useState} from 'react'
import {Link, useHistory, useLocation, Route, Redirect} from 'react-router-dom'
import styled from 'styled-components'
import forEach from 'lodash/forEach'
import shuffle from 'lodash/shuffle'

import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MuiLink from '@material-ui/core/Link'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MoreIcon from '@material-ui/icons/ChevronRight'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Header from './Header'
import ProjectSummaryCard from './ProjectSummaryCard'
import ResourceCard from './Resources/ResourceCard'
import Loading from './Loading'

import data from '../data/projects.json'
import resources from './Resources/resourcesData.json'
import MyProjects from './MyProjects'
import IlabProjects from './IlabProjects'
import useFetchMyProject from '../helpers/hooks/useFetchMyProject'
import useFetchILabProject from '../helpers/hooks/useFetchILabProject'
import useFetchPublicDetails from '../helpers/hooks/useFetchPublicDetails'

const shuffleProjects = data => {
	const {groupedHits, facets} = data
	const shuffledHits = {}
	forEach(groupedHits, (groupedValues, groupKey) => {
		shuffledHits[groupKey] = {}
		forEach(groupedValues, (array, key) => {
			shuffledHits[groupKey][key] = shuffle(array)
		})
	})
	return {
		shuffledHits,
		facets,
	}
}

const PageContainer = styled.main`
	margin: 2vh 2vw;
	@media (max-width: 880px) {
		margin: 2vh 2vw;
	}
`

const GroupingControlBar = styled(Toolbar).attrs(() => ({
	disableGutters: true,
}))`
	&.MuiToolbar-root {
		justify-content: space-between;
	}
`

const StyledTabs = styled(Tabs)`
	.MuiTab-root {
		font-size: 1.1rem;
	}
	margin: 2rem 0;

	@media (min-width: 768px) {
		.MuiTab-root {
			font-size: 1.3rem;
		}
	}

	@media (max-width: 375px) {
		.MuiTab-root {
			font-size: 0.88rem;
		}
	}
`

const CitySeparator = styled.div`
	margin-bottom: 24px;
	h1,
	h2,
	h3 {
		margin-bottom: 0;
		margin-right: 24px;
	}
	h2,
	h3 {
		color: rgba(0, 0, 0, 0.7);
		font-weight: 600;
		font-size: 24px;
	}
	h3 {
		font-size: 16px;
	}
	hr {
		height: 1px;
		background-color: #979797;
		border: none;
	}
	.city-title {
		display: flex;
		align-items: baseline;
		h2 {
			text-transform: capitalize;
		}
	}
	.MuiSvgIcon-root {
		vertical-align: bottom;
	}
`

export const ProjectCardsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: nowrap;
	& > * {
		margin-right: 24px;
		margin-bottom: 0;
		flex: 350px 1 0;
		/* width: 350px; */
		@media (max-width: 880px) {
			margin-right: 0;
			margin-bottom: 12px;
			width: 85vw;
		}
	}
`

const ResourceCardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
	grid-gap: 12px;
`

function getResourceCards(section, role) {
	return resources[section]
		.filter(({access}) => {
			if (access === 'all') return true
			else return access.includes(role)
		})
		.map(resource => <ResourceCard {...resource} key={resource.link} />)
}

const Home = () => {
	const [selectedFacet, setSelectedFacet] = useState('citiesOfOperation')
	const {shuffledHits, facets} = shuffleProjects(data)
	const history = useHistory()
	const pages = ['/projects', '/resources', '/myprojects', '/ilabprojects']
	const location = useLocation()
	const {
		isSuccess: isPublicDetailsSuccess,
		data: publicDetails,
	} = useFetchPublicDetails()

	const email = isPublicDetailsSuccess ? publicDetails.email : null
	const role = publicDetails?.publicDetails?.role

	const myProjectQuery = useFetchMyProject(email)
	const myProjectsList = myProjectQuery.data?.hits
	let showMyProjects = myProjectsList?.length > 0

	const iLabProjectsQuery = useFetchILabProject()
	const iLabProjectsList = iLabProjectsQuery.data?.hits
	let showILabProjects = iLabProjectsList?.length > 0

	const [selectedTab, setSelectedTab] = useState(() => {
		const index = pages.findIndex(i => i === location.pathname)
		if (index === pages.findIndex(i => i === '/myprojects')) return null
		else return Math.max(index, 0)
	})

	const matchesMD = useMediaQuery(theme => theme.breakpoints.up('md'))
	const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'))
	const matchesXL = useMediaQuery(theme => theme.breakpoints.up('xl'))

	let maxCardCount = 1
	if (matchesMD) maxCardCount++
	if (matchesLG) maxCardCount++
	if (matchesXL) maxCardCount++

	const facetOptions = [
		{
			name: 'citiesOfOperation',
			label: 'Cities',
		},
		{
			name: 'pathways',
			label: 'Pathways',
		},
	]
	const selectedFacetsData = facets[selectedFacet]

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue)
		history.push(pages[newValue])
	}

	return (
		<div>
			<Header />
			<PageContainer>
				<StyledTabs
					value={selectedTab}
					onChange={handleTabChange}
					variant="scrollable"
				>
					<Tab label="Explore Projects" />
					<Tab label="Explore Resources" />
					{showMyProjects && <Tab label="My Projects" />}
					{showILabProjects && <Tab label="iLab Projects" />}
				</StyledTabs>
				<Route exact path="/">
					<Redirect
						to={{pathname: '/projects', search: location.search}}
					/>
				</Route>
				<Route path="/projects">
					<GroupingControlBar>
						<div>
							Group Projects by:{' '}
							<ButtonGroup variant="outlined">
								{facetOptions.map(option => (
									<Button
										color={
											selectedFacet === option.name
												? 'primary'
												: 'default'
										}
										onClick={() =>
											setSelectedFacet(option.name)
										}
										key={option.name}
									>
										{option.label}
									</Button>
								))}
							</ButtonGroup>
						</div>
					</GroupingControlBar>
					{Object.keys(selectedFacetsData).map(facetKey => {
						return (
							<React.Fragment key={facetKey}>
								<CitySeparator>
									<div className="city-title">
										<h2>{facetKey}</h2>
										<MuiLink
											component={Link}
											to={{
												pathname: '/search',
												search: `?refinementList[${encodeURIComponent(
													selectedFacet
												)}][0]=${encodeURIComponent(
													facetKey
												)}`,
											}}
										>
											view all projects <MoreIcon />
										</MuiLink>
									</div>
									<hr />
								</CitySeparator>
								<ProjectCardsContainer>
									{shuffledHits[selectedFacet][facetKey]
										.slice(0, maxCardCount)
										.map(project => {
											return (
												<Link
													to={`/project/${project.projectId}`}
													key={project.projectId}
												>
													<ProjectSummaryCard
														projectData={project}
													></ProjectSummaryCard>
												</Link>
											)
										})}
								</ProjectCardsContainer>
							</React.Fragment>
						)
					})}
				</Route>
				<Route path="/resources">
					{isPublicDetailsSuccess ? (
						Object.keys(resources).map(section => (
							<ResourceSection
								role={role}
								section={section}
								key={section}
							/>
						))
					) : (
						<Loading />
					)}
				</Route>
				<Route path="/myprojects">
					<MyProjects />
				</Route>
				<Route path="/ilabprojects">
					<IlabProjects />
				</Route>
			</PageContainer>
		</div>
	)
}
export default Home

function ResourceSection({role, section}) {
	const resourcesCards = getResourceCards(section, role)
	return (
		<>
			<CitySeparator>
				<div className="city-title">
					<h2>{section}</h2>
					<h3>
						{resourcesCards.length} file
						{resourcesCards.length !== 1 && 's'}
					</h3>
				</div>
				<hr />
			</CitySeparator>
			<div>
				<ResourceCardContainer>{resourcesCards}</ResourceCardContainer>
			</div>
		</>
	)
}
