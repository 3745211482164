import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import {useQueryCache} from 'react-query'
import {Link} from 'react-router-dom'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import useFetchMyProject from '../helpers/hooks/useFetchMyProject'
import useFetchPublicDetails from '../helpers/hooks/useFetchPublicDetails'
import Loading from './Loading'

import ProjectSummaryCard from './ProjectSummaryCard'

const CardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
	grid-gap: 24px;
`

function MyProjects() {
	const queryCache = useQueryCache()
	const timeout = useRef([])
	const publicDetailsQuery = useFetchPublicDetails()
	const email = publicDetailsQuery.isSuccess
		? publicDetailsQuery.data.email
		: null
	const {data, isFetching, isSuccess, isError} = useFetchMyProject(email)
	const myProjectsList = data?.hits
	const projectsLength = myProjectsList?.length

	useEffect(() => {
		if (isSuccess && projectsLength === 0)
			timeout.current = [
				setTimeout(() => {
					queryCache.invalidateQueries(['myProject', email])
				}, 5000),
				setTimeout(() => {
					queryCache.invalidateQueries(['myProject', email])
				}, 15000),
			]
		return () => {
			timeout.current.forEach(t => {
				clearTimeout(t)
			})
		}
	}, [queryCache, email, isSuccess, projectsLength])

	let content = null

	if (isError) {
		content = (
			<Alert severity="error">
				There was an error fetching your projects, please try refreshing
				the page
			</Alert>
		)
	} else if (isSuccess && projectsLength === 0) {
		content = (
			<Alert severity="info">
				<AlertTitle>No Projects Found</AlertTitle>
				Your Project was not found. If you have just submitted your
				project, please wait for a few seconds, or try refreshing the
				page.
			</Alert>
		)
	} else if (isSuccess && projectsLength > 0) {
		content = (
			<CardContainer>
				{myProjectsList.map(project => {
					return (
						<Link
							to={`/project/${project.projectId}`}
							key={project.projectId}
						>
							<ProjectSummaryCard
								projectData={project}
							></ProjectSummaryCard>
						</Link>
					)
				})}
			</CardContainer>
		)
	}

	return (
		<div>
			{publicDetailsQuery.isFetching || (isFetching && <Loading />)}
			{content}
		</div>
	)
}

export default MyProjects
