import React from 'react'
import styled from 'styled-components'
import ProjectSummaryCard from './ProjectSummaryCard'
import {Link} from 'react-router-dom'
import useFetchILabProject from '../helpers/hooks/useFetchILabProject'

const CardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
	grid-gap: 24px;
`

function IlabProjects() {
	const iLabProjectsQuery = useFetchILabProject()
	const iLabProjectsList = iLabProjectsQuery.data?.hits
	let showILabProjects = iLabProjectsList?.length > 0
	return (
		<div>
			{showILabProjects && (
				<CardContainer>
					{iLabProjectsList.map(project => {
						return (
							<Link
								to={`/project/${project.projectId}`}
								key={project.projectId}
							>
								<ProjectSummaryCard
									projectData={project}
								></ProjectSummaryCard>
							</Link>
						)
					})}
				</CardContainer>
			)}
		</div>
	)
}

export default IlabProjects
